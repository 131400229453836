import React, { Component } from "react"
import PropTypes from "prop-types"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Seo from "../../components/seo"
import Accesibilidad from "../../components/accesibilidad"
import axios from "axios"

function getCookie(nombre) {
  if (typeof document !== `undefined`) {
    var name = nombre + "="
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1)
      if (c.indexOf(name) === 0)
        return unescape(c.substring(name.length, c.length))
    }
    return ""
  }
}

export default class Previews extends Component {
  state = {
    content: null,
    loading: false,
    url_string: this.props.location.href,
    site: null,
    guid: null,
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      const url = new URL(this.state.url_string)
      this.setState({ site: url.searchParams.get("site") })
      this.setState({ guid: url.searchParams.get("guid") })
      localStorage.setItem("guid", url.searchParams.get("guid"))
    }

    var guid = localStorage.getItem("guid")

    if (!guid) {
      guid = getCookie("ECDPreviewLink")
    }

    var data = JSON.stringify({
      guid: guid,
    })

    var self = this
    axios({
      method: "post",
      maxBodyLength: Infinity,
      url: "https://nube.megadev.com.co/api/PrestigioDigital/PostGetData",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(function (response) {
        self.setState({ content: response.data })
        self.setState({ loading: true })
      })
      .catch(function (error) {
        self.setState({ loading: true })
        console.log(error)
      })
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div>
            <Accesibilidad />
            <Header site={this.state.site || "personas"} />
            <Seo title="Personas" />
            <div
              style={{ margin: `2em auto 0 auto` }}
              className="text-gray-700"
            >
              <main className="mitexto" style={{ paddingBottom: "1rem" }}>
                <section className="page-content">
                  <div
                    layout="fullWidth"
                    dangerouslySetInnerHTML={{
                      __html: this.state.content,
                    }}
                  />
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </section>
              </main>
            </div>
            <Footer />
          </div>
        )}
      </>
    )
  }
}

Previews.propTypes = {
  siteTitle: PropTypes.string,
}

Previews.defaultProps = {
  siteTitle: ``,
}
